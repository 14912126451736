import { graphql, useStaticQuery } from "gatsby";

const useCourses = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "courses" }
          childMdx: { excerpt: { ne: null } }
        }
        sort: { fields: [childMdx___frontmatter___date], order: DESC }
        limit: 10
      ) {
        nodes {
          courses: childMdx {
            fields {
              slug
            }
            frontmatter {
              title
              description
              thumbnail {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
              banner
            }
            excerpt
          }
        }
      }
    }
  `);
  return data.allFile.nodes.map(({ courses }) => ({
    title: courses.frontmatter.title,
    description: courses.frontmatter.description,
    slug: courses.fields.slug,
    fluid: {
      ...courses.frontmatter.thumbnail.childImageSharp.fluid,
      originalName: courses.frontmatter.thumbnail.childImageSharp.fluid?.originalName?.replace(
        /Banner|.png/g,
        ""
      ),
    },
    banner: courses.frontmatter.banner,
    excerpt: courses.excerpt,
  }));
};

export default useCourses;
