import React from "react";

import Layout from "../components/layout";
import CoursePreview from "../components/CoursePreview";
import useCourses from "../hooks/useCourses";
import "../sass/main.scss";

const IndexPage = ({ location }) => {
  const courses = useCourses();
  return (
    <Layout
      showBanner={true}
      featuredCourse={{
        title: "دروس خصوصية مع الأستاذ بن أحمد",
        description: "لكل مستويات الطور الثانوي في مادة العلوم الفيزيائية",
        btn: "إقرأ المزيد",
        slug: "/دروس-خصوصية",
        banner: "magnet",
      }}
      title="دروس وتمارين"
      classSection="Main"
      location={location}
    >
      <div className="posts">
        {courses.length % 2 === 0
          ? courses.map((course, index) => {
              return (
                <>
                  <CoursePreview
                    key={course.title}
                    loading={index === 0 ? "eager" : "lazy"}
                    course={course}
                  />
                </>
              );
            })
          : [
              ...courses.map((course, index) => {
                return (
                  <>
                    <CoursePreview
                      key={course.title}
                      loading={index === 0 ? "eager" : "lazy"}
                      course={course}
                    />
                  </>
                );
              }),
              <div key={1}></div>,
            ]}
      </div>
    </Layout>
  );
};

export default IndexPage;
